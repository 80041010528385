<template>
    <div class="sign" style="padding-top:30px">
        <el-radio-group v-model="newId">
            <el-radio v-for="(item) in list" :key="item.user_id" :label="item.user_id">
                <span style="margin-right:20px">{{item.user_name}}</span>
                <span>{{item.mobile}}</span>
            </el-radio>
        </el-radio-group>
        <div class="flex flex-justify-around" style="padding: 30px 80px 0;">
            <el-button @click="hide()" style="width:76px;" size="small" type="primary" plain>取消</el-button>
            <el-button @click="submit()" style="width:76px;" size="small" type="primary">确定</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['user_id','sealMember','popTitle'],
        data() {
            return {
                list: [],
                newId: null,
            }
        },
        watch: {
            user_id:{
                handler() {
                    if (this.user_id) {
                        this.newId = this.user_id;
                    }
                },
                deep: true
            }
        },
        created () {
            if (this.user_id) {
                this.newId = this.user_id;
            }
            this.list = this.sealMember;
            this.list.forEach((e)=>{
                e.user_name = e.user_name.slice(0,1)+"**";
                e.mobile = e.mobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
            })
            console.log("newId",this.newId);
        },
        methods: {
            hide() {
                this.$emit('hide');
            },
            submit() {
                this.$emit('success',this.newId);
                this.$emit('hide');
            },
        }
    }
</script>
<style scoped>
    .sign >>> .el-radio-group .el-radio {
        width: 60%;
        display: inline-block;
        vertical-align: top;
        margin: 0 0 20px;
    }
    .sign {
        text-align: center;
    }
    .sign .sign-icon {
        font-size: 14px;
        color: #2BBAAE;
    }
    .sign-title {
        padding: 25px;
        font-size: 14px;
        font-weight: 500;
        color: #808080;
        line-height: 20px;
        border-bottom: 1px solid #E6E6E6;
    }
    .sign-button {
        width: 110px;
        height: 30px;
        line-height: 30px;
        margin: 0 auto;
        line-height: 30px;
        background: #24B1EB;
        font-size: 14px;
        /* color: #FAFAFA; */
        color: #fff;
        cursor: pointer;
        -webkit-user-select:none;
        -moz-user-select:none;
        margin-top: 30px;
    }
    .sign-text {
        font-size:12px;color:#B3B3B3;
        padding: 20px 0;
    }
</style>